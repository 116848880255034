* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 75%;
  margin: auto;

}

.games {
  width: 200px;
  height: 300px;
  border: 1px #404040 solid;
  margin: 1rem;
}

.games img {
  width: 100%;
  max-width: fit-content;
  text-align: center;
}

.games-info {
  padding: 1rem;
}

.img-large {
  max-width: 100%;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}